import React from "react";
import Link from "next/link";
import {
  FaRegSmile,
  FaHeart,
  FaChild,
  FaBrain,
  FaHandsHelping,
  FaBriefcase,
  FaHandshake,
  FaWineBottle,
  FaEllipsisH,
} from "react-icons/fa";

const services = [
  {
    title: "Individual Counselling",
    link: "/services/individual-counselling",
    description:
      "Empower your personal growth with one-on-one guidance for mental well-being, self-esteem, and stress management. Our services are tailored to meet each client&apos;s unique needs, ensuring a personalized approach to therapy.",
    icon: <FaRegSmile className="w-8 h-8" />,
  },
  {
    title: "Couple and Family Counselling",
    link: "/services/couple-and-family-counselling",
    description:
      "Improve communication, resolve conflicts, and foster deeper connections in your relationships.",
    icon: <FaHeart className="w-8 h-8" />,
  },
  {
    title: "Demographic-Specific Counselling",
    link: "/services/demographic-specific-counselling",
    description:
      "Tailored therapeutic approaches for all ages, from children to seniors, ensuring personalized care.",
    icon: <FaChild className="w-8 h-8" />,
  },
  {
    title: "Disorders Counselling",
    link: "/services/disorders-counselling",
    description:
      "Effective strategies and coping techniques for anxiety, depression, PTSD, and other mental health concerns.",
    icon: <FaBrain className="w-8 h-8" />,
  },
  {
    title: "Abuse Counselling",
    link: "/services/abuse-counselling",
    description:
      "Compassionate counselling to help individuals heal from emotional, physical, and psychological trauma.",
    icon: <FaHandsHelping className="w-8 h-8" />,
  },
  {
    title: "Career and Education Counselling",
    link: "/services/career-and-education-counselling",
    description:
      "Guidance to navigate professional challenges, career transitions, and academic decisions.",
    icon: <FaBriefcase className="w-8 h-8" />,
  },
  {
    title: "Mediation and Consultation Counselling",
    link: "/services/mediation-and-consultation-counselling",
    description:
      "Expert conflict resolution and consulting to foster cooperation and positive outcomes.",
    icon: <FaHandshake className="w-8 h-8" />,
  },
  {
    title: "Addictions Counselling",
    link: "/services/addictions-counselling",
    description:
      "Supportive treatment for substance misuse and addiction recovery, promoting long-term wellness.",
    icon: <FaWineBottle className="w-8 h-8" />,
  },
  {
    title: "Other Specializations Counselling",
    link: "/services/other-specializations-counselling",
    description:
      "Explore custom therapy solutions designed to address unique personal and situational challenges.",
    icon: <FaEllipsisH className="w-8 h-8" />,
  },
];

const Services: React.FC = () => {
  return (
    <div className="min-h-screen">
      {/* Enhanced Services Grid */}
      <section className="relative py-24 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-20">
            <h2 className="text-md font-bold text-emerald-800 tracking-wide uppercase mb-6">
              Comprehensive Care
            </h2>
            <h3 className="text-4xl font-extrabold text-slate-900 sm:text-5xl">
              Specialized Therapeutic Solutions
            </h3>
            <p className="mt-6 max-w-2xl mx-auto text-lg text-slate-600/95 leading-relaxed">
              Evidence-based approaches delivered with compassion and professionalism to address life&apos;s complex challenges.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div
                key={index}
                className="group relative bg-white rounded-2xl p-8 shadow-sm hover:shadow-md transition-shadow duration-300 border border-slate-100 hover:border-emerald-200"
              >
                <div className="mb-8 p-5 bg-gradient-to-br from-emerald-500 to-teal-400 rounded-xl text-white w-fit shadow-md">
                  {React.cloneElement(service.icon, { className: "w-10 h-10" })}
                </div>
                <h3 className="text-2xl font-bold text-slate-900 mb-5">
                  {service.title}
                </h3>
                <p className="text-slate-600/90 leading-relaxed mb-7 text-lg">
                  {service.description}
                </p>
                <Link
                  href={service.link}
                  className="mt-auto font-semibold text-emerald-700 hover:text-emerald-600 transition-colors flex items-center gap-3"
                >
                  <span className="hover:underline decoration-2 underline-offset-4">
                    Explore Service
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;